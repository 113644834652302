import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import "./LoadingSpinner.scss";
import Utils from "../../Utils";
import {getKeyName} from "../../types/KeyName";
import complexImage from "../../assets/entities/drhouse_complex.png";
import deviceImage from "../../assets/entities/wikipedia_door.png";
import gatewayImage from "../../assets/entities/random_router.png";
import accessImage from "../../assets/entities/wikipedia_access.png";
import keyImage from "../../assets/entities/wikipedia_key.png";
import roomImage from "../../assets/entities/portal2_room.png";
import stayImage from "../../assets/entities/eleanor_stay.png";
import userImage from "../../assets/entities/eleanor_user.png";
import {EntityType} from "../../types/EntityType";
import "./EntityImage.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CONFIG_FILE} from "../../types/ConfigFile";
import {getKeyType} from "../../types/KeyType";

export default function EntityImage(props: {
    entityType: EntityType,
    entity: any,
    shape: 'landscape73' | 'square'
    customImage?: string,
    onUploadNewImage?: (image: Blob) => void
}) {
    const {t} = useTranslation();

    const [uploadedImage, setUploadedImage] = useState<any>();

    const uploadImage = () => {
        if (!props.onUploadNewImage) {
            return;
        }
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = e => {
            // getting a hold of the file reference
            if (!e.target) {
                return;
            }
            // @ts-ignore
            let file: Blob = e.target.files[0];

            // setting up the reader
            let reader = new FileReader();
            reader.readAsDataURL(file);
            //reader.readAsText(file, 'UTF-8');
            props.onUploadNewImage!(file);

            // here we tell the reader what to do when it's done reading...
            reader.onload = readerEvent => {
                let content = readerEvent.target?.result; // this is the content!
                if (!content) {
                    return;
                }
                setUploadedImage(content);
            }

        }

        input.click();
    }

    const getEntityImageAndHueRadius = (): { image: string, hueRadius: number } => {
        if (!props || !props.entity || typeof props.entity !== 'object') {
            return {image: '', hueRadius: 0};
        }

        // Return custom image
        if (props.customImage) {
            return {image: props.customImage, hueRadius: 0};
        }

        // Find default image
        let result: any = {};
        switch (props.entityType) {
            case "key":
                result.image = keyImage;
                result.hueRadius = Utils.getHashRadius(getKeyName(props.entity));
                break;
            case "device":
                result.image = deviceImage;
                if (props.entity.category === 'Cerradura Inteligente') {
                    result.image = deviceImage;
                }
                if (props.entity.category === 'Gateway') {
                    result.image = gatewayImage;
                }
                if (props.entity.category === 'Dispositivo acceso') {
                    result.image = accessImage;
                }
                
                result.hueRadius = Utils.getHashRadius(props.entity.nmk_description);
                break;
            case "room":
                result.image = roomImage;
                result.hueRadius = Utils.getHashRadius(props.entity.name);
                break;
            case "complex":
                result.image = complexImage;
                result.hueRadius = Utils.getHashRadius(props.entity.name);
                break;
            case "stay":
                result.image = stayImage;
                result.hueRadius = Utils.getHashRadius(props.entity.idstay);
                break;
            case "user":
                result.image = userImage;
                result.hueRadius = Utils.getHashRadius(props.entity.email);
                break;
        }
        return result;
    }

    const getCustomImage = (): string | undefined => {
        if (!props.customImage) {
            return undefined;
        }
        let url = props.customImage;
        url = url?.replace('uploaded', 'download');
        return CONFIG_FILE.basePath + '/' + url;// + '?' + Math.random();// Uncomment to use cache
    }

    return <div
        className={'entityImage ' + ((props.shape === 'square') ? 'squareImgContainer ' : '') + (props.onUploadNewImage ? 'hasUploadIcon ' : '')}>

        <img style={{
            cursor: props.onUploadNewImage ? 'pointer' : undefined
        }} src={uploadedImage ?? getCustomImage() ?? getEntityImageAndHueRadius().image} alt="" onClick={() => uploadImage()}/>

        {props.entityType === 'key' ?
            <div className={'keyTypeIcon'}>
                {getKeyType(props.entity)?.icon}
            </div> : null
        }

        {props.onUploadNewImage ?
            <div className={'uploadIcon'} onClick={() => uploadImage()}>
                <FontAwesomeIcon icon={['fas', 'file-upload']}/>
                <div>{t('actions.uploadNewImage')}</div>
            </div> : null}

    </div>;

}

