import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Device, Key} from "../../api/generated/Entities";
import {ExtendedKey} from "../../api/ExtendedEntities"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
import {
    clearKeyType,
    clearExtendedKeyType,
    getKeyType,
    getKeyTypeDescriptionI18N,
    getKeyTypeFromFeature,
    getKeyTypeI18N,
    KEYTYPE,
    KeyType,
    KeyTypes
} from "../../types/KeyType";
import {getKeyName} from "../../types/KeyName";
import EntityImage from "../misc/EntityImage";
import ModalKeyQR from "../misc/ModalKeyQR";
import NfcInput from "../misc/NfcInput";
import IntegerInput from "../misc/IntegerInput";
import GeolocationMap from "../misc/GeolocationMap";


export default function KeyEdit(props: {
    id: number,
    onSave: () => void,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [keyObject, setKeyObject] = useState<ExtendedKey>({});
    const [devices, setDevices] = useState<Device[]>([]);
    const [modalQR, setModalQR] = useState<string | undefined>();

    useEffect(() => {
        if (props.id > 0) {
            Api.searchKey({id: props.id}).then(value => {
                if (!Api.isError(value, 'mustBeArrayWithObject')) {
                    const key: Key = value.content[0];
                    // "nmk_geo = null" is used to activate GeolocationMap component, so I put nmk_geo to undefined
                    // to prevent map to load
                    if (key.nmk_geo === null || key.nmk_geo === 'null') {
                        console.log('Key received with nmk_geo = ', key.nmk_geo, ' so I set it to undefined to prevent map loading');
                        key.nmk_geo = undefined;
                    } else {
                        console.log('Key received with nmk_geo =', key.nmk_geo);
                    }
                    setKeyObject(key);
                }
            })
        }
        Api.searchDevice().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setDevices((value.content as Device[]).filter(device => device.category === 'Cerradura Inteligente' || device.category === 'Dispositivo acceso'));
            }
        });
    }, []);

    useEffect(() => {
        // Set undefined nmk_geo if key type is "key" (physical key)
        if (keyObject.user_or_key === 'key' && keyObject.nmk_geo) {
            setKeyObject(prevState => {
                return {...prevState, nmk_geo: undefined};
            });
        }
        // Set undefined nmk_key_str if no NFC key type selected
        if (getKeyType(keyObject)?.id !== KEYTYPE.NFC && getKeyType(keyObject)?.id !== KEYTYPE.PADKEY && keyObject.nmk_key_str) {
            setKeyObject(prevState => {
                return {...prevState, nmk_key_str: undefined};
            });
        }
    }, [keyObject]);

    useEffect(() => {
        if (keyObject.idkey) {
            return; // In edit mode I don't want to remove the key type
        }
        let selectedDevice = devices.find(d=> d.iddevice === keyObject.device_iddevice);
        let availableKeyTypes = devices.find(d => d.iddevice === keyObject.device_iddevice)?.features?.map(f => getKeyTypeFromFeature(f)?.id);
        if (!availableKeyTypes || availableKeyTypes.length === 0 || !availableKeyTypes.includes(getKeyType(keyObject)?.id)) {
            // Executed if device changes and selected key type is not supported by the device
            // Clean the key type
            let keyClone = clearExtendedKeyType(keyObject);
            keyClone.ex_device = selectedDevice;
            setKeyObject(() => {
                return {...keyClone};
            });
        }
    }, [keyObject.device_iddevice]);


    const save = () => {
        // nmk_user_description and description come with the same string, but I filter it because key type
        let keyClone: ExtendedKey = {...keyObject};
        let keyType: KeyType | undefined = getKeyType(keyObject);
        if (!keyType) {
            alert(t('key.errors.noKeyType'));
            return;
        }

        // Remove bad names
        if (keyType.userOrKey === 'key') {
            delete keyClone.nmk_user_description;
        } else if (keyType.userOrKey === 'user') {
            delete keyClone.description;
        }

        // If key is QR (user type non remote) then generate user and password. Only create mode.
        let userToken: string | undefined;
        let passwordToken: string | undefined;
        if (keyType.id === KEYTYPE.QR && !keyClone.idkey) {
            userToken = Utils.generateAlphanumericToken(14);
            passwordToken = Utils.generateAlphanumericToken(14);
            keyClone.nmk_user_username = userToken;
            keyClone.nmk_user_password = CryptoJS.MD5(passwordToken).toString();
        }

        console.log('Saving key with nmk_geo =', keyClone.nmk_geo);

        // Save key
        Api.setKey(keyClone).then(value => {
            if (Api.isError(value)) {
                return;
            }
            // If is edit mode, no QR popup
            if (keyClone.idkey) {
                props.onSave();
                return;
            }
            // NFC keys doesn't need QR popup
            if (keyType!.id === KEYTYPE.NFC) {
                props.onSave();
                return;
            }
            keyObject.idkey = value.content?.idkey;
            // For 'REMOTE' key type the QR code comes in the response:
            if (keyType!.id === KEYTYPE.REMOTE) {
                if (value.qrcode) {
                    setModalQR(value.qrcode);
                } else {
                    alert('value.qrcode not found');
                }
                // Modal will execute props.onSave() when closed
                return;
            }
            if (keyType!.id === KEYTYPE.QR) {
                Api.getNomorepassUserPasswordQR(value.content, userToken!, passwordToken!).then(qrCode => {
                    if (typeof qrCode === 'string') {
                        setModalQR(qrCode);
                        // Modal will execute props.onSave() when closed
                    } else {
                        alert('Error getting the nomorepass QR code');
                    }
                });
                return;
            }
            // I have to get the QR code from nomorepass:
            if (keyType!.userOrKey === 'key' && value.content?.nmk_key_str) {
                // Nomorepass needs the device for the key so we must use extended key
                let extendedContent = value.content as ExtendedKey;
                extendedContent.ex_device = keyClone.ex_device;
                Api.getNomorepassKeyQR(extendedContent, value.content.nmk_key_str).then(qrCode => {
                    if (typeof qrCode === 'string') {
                        setModalQR(qrCode);
                        // Modal will execute props.onSave() when closed
                    } else {
                        alert('Error getting the nomorepass QR code');
                    }
                });
                return;
            }
            props.onSave();
        });
    }

    const closeQRModal = () => {
        setModalQR(undefined);
        props.onSave();
    }

    return <div>
        <ModalKeyQR key={modalQR} qrCode={modalQR} keyObject={keyObject} onClose={closeQRModal}/>
        <div className="header">
            <EntityImage entityType="key" entity={keyObject} shape="landscape73"/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="name">{t('name')}</label>
            <input className="form-control" id="name" value={getKeyName(keyObject)}
                   onChange={(event) => setKeyObject({
                       ...keyObject, // Is setted in both parameters, but filtered in save function
                       description: event.target.value,
                       nmk_user_description: event.target.value
                   })}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="associatedDevice">{t('key.associatedDevice')}</label>
            <select className="form-select" id="associatedDevice" value={keyObject?.device_iddevice}
                    disabled={!!keyObject.idkey}
                    onChange={(event) => setKeyObject({
                        ...keyObject,
                        device_iddevice: parseInt(event.target.value)
                    })}>
                <option value="0">{t('none')}</option>
                {
                    devices.map(device =>
                        <option value={device.iddevice}>{device.nmk_description}</option>
                    )
                }
            </select>
        </div>
        <div className={'mb-3 d-flex flex-column'}>
            <label className="form-label" htmlFor="type">{t('key.type')}</label>
            <div className={'d-flex gap-2'} style={{height: '240px'}}>
                <div className={'card flex-shrink-0 flex-grow-1 btn-group-vertical'}>
                    {KeyTypes.map(type => {
                        return <button
                            className={'text-start text-nowrap btn ' + (type.id === getKeyType(keyObject)?.id ? 'btn-primary' : '')}
                            id={'type' + type.id}
                            disabled={!devices.find(d => d.iddevice === keyObject.device_iddevice)?.features?.map(f => getKeyTypeFromFeature(f)?.id).includes(type.id) || !!keyObject.idkey}
                            onClick={(event) =>
                                setKeyObject({
                                    ...keyObject,
                                    user_or_key: type.userOrKey,
                                    nmk_key_type: type.keyTypeId,
                                    user_remote: type.userIsRemoteKey
                                })}>
                            {type.icon} <span className={'ms-1 me-3'}>{getKeyTypeI18N(t, type)}</span>
                        </button>
                    })}
                </div>
                {getKeyType(keyObject)?.id ? <div className={'card p-2 overflow-auto'}>
                    <strong>{t('key.description')}:</strong>
                    {getKeyTypeDescriptionI18N(t, keyObject)}
                </div> : null}
            </div>
        </div>
        <div className={'mb-3 ' + (getKeyType(keyObject)?.id === KEYTYPE.NFC ? '' : 'd-none')}>
            <label className="form-label" htmlFor="nfcKeyCode">{t('key.nfcKey')}</label>
            <NfcInput value={keyObject.nmk_key_str ?? ''} id={'nfcKeyCode'} disabled={!!keyObject.idkey}
                      onChange={(event) => setKeyObject(prevState => {
                          return {...prevState, nmk_key_str: event.target.value}
                      })}/>
            <div className="invalid-feedback">
                {t('key.errors.nfcBadFormat')}
            </div>
        </div>
        <div className={'mb-3 ' + (getKeyType(keyObject)?.id === KEYTYPE.PADKEY ? '' : 'd-none')}>
            <label className="form-label" htmlFor="padKeyCode">{t('key.padKey')}</label>
            <IntegerInput value={keyObject.nmk_key_str ?? ''} id={'padKeyCode'} disabled={!!keyObject.idkey}
                        onChange={(event) => setKeyObject(prevState => {
                            return {...prevState, nmk_key_str: event.target.value}
                        })}/>
            <div className="invalid-feedback">
                {t('key.errors.integerBadFormat')}
            </div>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="timeFrom">{t('time.from')}</label>
            <input className="form-control" id="timeFrom" value={Utils.timestampToInputDateTime(keyObject?.nmk_datefrom)}
                   type="datetime-local"
                   onChange={(event) => {
                       setKeyObject({...keyObject, nmk_datefrom: Utils.inputDateToTimestamp(event.target.value)})
                   }}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="timeTo">{t('time.to')}</label>
            <input className="form-control" id="timeTo" value={Utils.timestampToInputDateTime(keyObject?.nmk_dateto)}
                   type="datetime-local"
                   onChange={(event) => {
                       setKeyObject({...keyObject, nmk_dateto: Utils.inputDateToTimestamp(event.target.value)})
                   }}/>
        </div>
        <div className={'form-check mb-3 ' + (keyObject.user_or_key === 'user' ? '' : 'd-none')}>
            <input type="checkbox" className="form-check-input" id="hasGeolocationLimit"
                   checked={!!keyObject.nmk_geo}
                   disabled={!!keyObject.idkey}
                   onChange={(event) => setKeyObject({
                       ...keyObject,
                       nmk_geo: event.target.checked ? 'null' : undefined
                   })}/>
            <label className="form-check-label" htmlFor="hasGeolocationLimit">{t('key.hasGeolocationLimit')}</label>
        </div>
        {
            keyObject.nmk_geo ?
                <GeolocationMap geolocation={JSON.parse(keyObject.nmk_geo)} readonly={!!keyObject.idkey}
                                onChange={(geo) => setKeyObject({...keyObject, nmk_geo: JSON.stringify(geo)})}/>
                : null
        }
        <div className="d-flex justify-content-evenly">
            <button className="btn btn-primary" onClick={() => save()}>
                <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                <span>{t('actions.save')}</span>
            </button>
            <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>
        </div>
    </div>;
}

