export const es = {
    entity: {
        key: 'Llave',
        key_plural: 'Llaves',
        device: 'Dispositivo',
        device_plural: 'Dispositivos',
        room: 'Habitación',
        room_plural: 'Habitaciones',
        complex: 'Complejo',
        complex_plural: 'Complejos',
        stay: 'Estancia',
        stay_plural: 'Estancias',
        user: 'Usuario',
        user_plural: 'Usuarios',
        log: 'Historial'
    },
    profile: 'Perfil',
    myProfile: 'Mi perfil',
    actions: {
        save: 'Guardar',
        edit: 'Editar',
        delete: 'Eliminar',
        markToDelete: 'Marcar para borrar',
        unmark: 'Desmarcar',
        create: 'Crear',
        new: 'Nuevo',
        new_female: 'Nueva',
        add: 'Añadir',
        close: 'Cerrar',
        cancel: 'Cancelar',
        share: 'Compartir',
        yes: 'Sí',
        no: 'No',
        see: 'Ver',
        change: 'Cambiar',
        logout: 'Salir',
        filter: 'Filtrar',
        filterDates: 'Filtrar por fechas',
        open: 'Abrir',
        login: 'Iniciar sesión',
        login_order: 'Inicie sesión',
        signIn: 'Registrarse',
        signIn_order: 'Regístrese',
        signInAsGuest: '$t(actions.signIn) como huésped',
        signInAsGuest_order: '$t(actions.signIn_order) como huésped',
        signInAsOwner: '$t(actions.signIn) como propietario',
        signInAsOwner_order: '$t(actions.signIn_order) como propietario',
        passwordRepeat: 'Repita la contraseña',
        resetPass: 'Restablecer contraseña',
        resetPass_order: 'Restablece la contraseña',
        uploadNewImage: 'Subir imagen nueva',
        resendValidation: 'Reenviar correo de validación'
    },
    questions: {
        noAccount: '¿No tiene cuenta?',
        alreadyAccount: '¿Ya tiene cuenta?',
        passwordForgotten: '¿Olvidó su contraseña?'
    },
    nomorepass: {
        tip: 'No vuelva a olvidar su contraseña con',
        discover: 'Descubre nomorepass'
    },
    errors: {
        pleaseFillAllFields: 'Por favor, rellene todos los campos del formulario',
        badEmail: 'Correo electrónico no válido',
        badRepeatedPassword: 'Las contraseñas no coinciden',
        badUserOrPassword: 'Usuario o contraseña incorrectos',
        noData: 'Sin datos',
        noId: 'Sin id'
    },
    time: {
        from: 'Desde',
        to: 'Hasta',
        start: 'Inicio',
        end: 'Fin',
        date: 'Fecha',
        expire: 'Expira',
        last24hours: 'Últimas 24h',
        lastWeek: 'Última semana',
        custom: 'Personalizado'
    },
    status: {
        status: 'Estado',
        active: 'Activo',
        inactive: 'Inactivo',
        activated: 'Activado',
        inactivated: 'Desactivado'
    },
    viewMode: {
        grid: 'Vista en tarjetas',
        list: 'Vista en lista'
    },
    id: 'Id',
    name: 'Nombre',
    noName: 'Sin nombre',
    none: 'Ninguno',
    none_female: 'Ninguna',
    all: 'Todos',
    all_masculine: 'Todos los',
    all_female: 'Todas las',
    type: 'Tipo',
    of: 'De',
    for: 'Para',
    in: 'En',
    or: 'O',
    remaining: 'Restante',
    remaining_plural: 'Restantes',
    loading: 'Cargando',
    selected: 'Seleccionado',
    selected_female: 'Seleccionada',
    selected_plural: 'Seleccionados',
    selected_female_plural: 'Seleccionadas',
    youHave: 'Tienes',
    unnamed: 'Sin nombre ',
    placeholders:{
        email: 'Introducir email',
        send: 'Enviar'
    },
    geolocation: {
        geolocation: 'Geolocalización',
        map: 'Mapa',
        lat: 'Latitud',
        lon: 'Longitud',
        rad: 'Radio',
        showInputs: 'Mostrar más campos',
        hideInputs: 'Ocultar campos extra'
    },
    user: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        successfulSignin: 'Se ha registrado correctamente',
        resetEmailSent: 'Correo electrónico enviado',
        resendValidationSent: 'Correo de validación reenviado',
        revalidationTitle: 'Correo no validado',
        revalidationText1: 'Revise su bandeja de entrada y carpeta de spam para buscar el correo que le hemos enviado para completar su proceso de validación.',
        revalidationText2: 'Si no encuentra el correo podemos volver a enviárselo pulsando el siguiente botón.'
    },
    key: {
        type: 'Tipo de llave',
        types: { /* From enum KEYTYPE */
            '0': '',
            '1': 'Ultrasonidos',
            '2': 'Luz',
            '3': 'NFC',
            '4': 'QR',
            '5': 'Remota',
            '6': 'Manual',
            '7': 'BLE',
            '8': 'Padkey'
        },
        description: 'Descripción',
        typesDescription: { /* From enum KEYTYPE */
            '0': '',
            '1': 'Llaves que utilizan ultrasonidos para enviar las credenciales a la cerradura.',
            '2': 'Llaves que permiten enviar mediante pulsos de luz credenciales a la cerradura.',
            '3': 'El sistema soporta tokens nfc, en forma de tarjeta o de llavero con el formato Mifare classic o Mifare Ultralight. Este tipo de llaves no se almacenan en el móvil sino que son dispositivos físicos que hay que entregar físicamente al usuario. Tiene utilidad como entrada de emergencia, en caso de no disponer de internet o si el usuario ya tiene integradas estas llaves para otros menesteres.',
            '4': 'Llaves que utilizan un QR de un solo uso para intercambiar por internet las claves cifradas.',
            '5': 'Llaves que se pueden utilizar directamente desde el teléfono móvil sin tener que estar presencialmente delante de la cerradura.',
            '6': '',
            '7': 'Llaves que permiten enviar mediante tecnología Bluetooth Low Energy credenciales a la cerradura.',
            '8': 'Llaves que se pueden introducir manualmente en un teclado'
        },
        keyName: 'Nombre de llave',
        nfcKey: 'Clave NFC',
        padKey: 'Clave teclado',
        userDescription: 'Título',
        associatedDevice: 'Dispositivo asociado',
        hasGeolocationLimit: 'Limitar por geolocalización',
        lastUse: 'Último uso',
        timesUsed: 'Veces usada',
        modalQRTip: 'Escanee el siguiente código QR con el dispositivo móvil donde desea almacenar la llave o envíela por email',
        modalButtonAlreadyScanned: 'Ya lo he escaneado',
        modalButtonAlreadyEmailed: 'Ya lo he enviado',
        modalButtonSendEmail: 'Enviar enlace por email...',
        errors: {
            noKeyType: 'No ha seleccionado un tipo de llave',
            nfcBadFormat: 'El formato de la clave NFC no es correcto',
            integerBadFormat: 'Las llaves de teclado solo aceptan dígitos',
            keyNotSaved: 'Llave no guardada'
        }
    },
    device: {
        customer: 'Cliente',
        description: 'Descripción',
        lastPing: 'Última conexión',
        associatedRoom: 'Habitación asociada',
        category: 'Categoría',
        categories: {
            smartLock: 'Cerradura inteligente',
            gateway: 'Gateway',
            accessDevice: 'Dispositivo acceso'
        },
        hardwareId: 'Identificador hardware',
        featuresAvailable: 'Características disponibles',
        mainStatus: {
            locked: 'Cerrado',
            unlocked: 'Abierto',
            armed: 'Armada',
            unarmed: 'Desarmada',
            unknown: 'Desconocido'
        },
        savedOkMessage: 'Dispositivo creado correctamente con código de activación',
        mustHaveFeature: 'El dispositivo debe tener al menos una característica seleccionada'
    },
    room: {
        associatedComplex: 'Complejo asociado',
        noDevicesFoundForRoom: 'La habitación no tiene dispositivos'
    },
    stay: {
        associatedRoom: 'Habitación asociada',
        guestsAndAssignedKeys: 'Huéspedes y sus llaves asignadas',
        keysToCreate: 'Llaves a crear',
        noKeys: 'Sin llaves',
        guest: 'Huésped',
        guest_plural: 'Huéspedes',
        guestsEmails: 'Emails de los huéspedes',
        guestsEmailPlaceholderTip: 'Escribe un email y pulsa el botón +',
        confirmCreateWithoutEmails: 'No ha introducido ningún email de huésped, ¿desea continuar igualmente?',
        confirmCreateWithoutKeys: 'No va a crear ninguna llave, ¿desea continuar igualmente?',
        confirmInputEmailNotAdded: 'Has introducido un email en el campo "$t(stay.guestsEmails)" pero no lo has añadido a la lista, ¿desea continuar igualmente?',
        deleteAllKeysOfGuest: 'Eliminar todas las llaves del huésped',
        deleteGuest: 'Eliminar huésped de la estancia',
        changeRoomConfirm: 'Va a eliminar todas las llaves creadas para la habitación actual, ¿desea continuar?',
        sendAllCreatedKeysByEmail: 'Enviar todas las llaves nuevas por email',
        emailsSent: 'Correos enviados',
        extraRoom: 'Habitación extra',
        extraRooms: 'Habitaciones extra',
        state:{
            all: 'Todas',
            past: 'Pasadas',
            current: 'En curso',
            future: 'Futuras',
            valid: 'Válidas'
        },
        locatorCode: 'Localizador'
    },
    log: {
        message: 'Mensaje',
        granted: '✔ ️Aceptado',
        noGranted: '❌ No aceptado',
        accessType: 'Tipo de acceso',
        events: { /* From enum EVENTLOG */
            '0': '❌ Desconocido',                          // UNKNOW
            '1': '✔ Cerrado',                              // LOCK                             // AVISO DE BLOQUEO
            '2': '✔ Abierto',                              // UNLOCK                           // AVISO DE DESBLOQUEO
            '3': '❌ Fallo al cerrar',                      // LOCK_FAILURE_INVALID_PIN_OR_ID   // AVISO DE BLOQUEO ERRONEO
            '4': '❌ Fallo al cerrar (horario erróneo)',    // LOCK_FAILURE_INVALID_SCHEDULE    // AVISO DE BLOQUEO ERRONEO (HORARIO ERRONEO)
            '5': '❌ Fallo al abrir',                       // UNLOCK_FAILURE_INVALID_PIN_OR_ID // AVISO DE DESBLOQUEO ERRONEO
            '6': '❌ Fallo al abrir (horario erróneo)',     // UNLOCK_FAILURE_INVALID_SCHEDULE  // AVISO DE DESBLOQUEO ERRONEO (HORARIO ERRONEO)
            '7': 'ONE_TOUCH_LOCK',          // UNUSED      // ONE_TOUCH_LOCK
            '8': 'KEY_LOCK',                // UNUSED      // KEY_LOCK
            '9': 'KEY_UNLOCK',              // UNUSED      // KEY_UNLOCK
            '10': 'AUTO_LOCK',              // UNUSED      // AUTO_LOCK
            '11': '✔ Cerrado programado',                  // SCHEDULE_LOCK
            '12': '✔ Abierto programado',                  // SCHEDULE_UNLOCK
            '13': '✔ Cerrado manual',                      // MANUAL_LOCK                             // AVISO DE BLOQUEO MANUAL
            '14': '✔ Apertura manual',                     // MANUAL_UNLOCK                           // AVISO DE DESBLOQUEO MANUAL
            '15': '✔ Solicitud código QR',                 // QR_REQUEST                              // MANDAR PETICION DE GENERAR QR
            '25': '❌ Fallo al cerrar (credenciales inválidas)', // LOCK_FAILURE_INVALID_CREDENTIAL    // AVISO DE CREDENCIALES ERRONEAS AL BLOQUEAR
            '26': '❌ Fallo al abrir (credenciales inválidas)',  // UNLOCK_FAILURE_INVALID_CREDENTIAL  // AVISO DE CREDENCIALES ERRONEAS AL DESBLOQUEAR
            '29': '❌ Generación de código QR abortada',         // CODE_QR_ABORT                      // MANDAR PETICION DE ABORTAR GENERACION DE QR
        }
    }
}
