import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Device} from "../../api/generated/Entities";
import Utils from "../../Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import {ExtendedDevice, ExtendedEntitiesFactory} from "../../api/ExtendedEntities";
import LogModal from "../misc/LogModal";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
import _ from "lodash";
import {useInterval} from "../../hooks/useInterval";
import ConditionalLink from "../misc/ConditionalLink";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Devices(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [devices, setDevices] = useState<Array<ExtendedDevice>>([]);
    const [logDeviceSelected, setLogDeviceSelected] = useState<number | undefined>();
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getDevices();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getDevices();
    }, [props.update]);

    const getDevices = async () => {
        let devicesResponse: any = await Api.searchDevice();
        if (Api.isError(devicesResponse, 'mustBeArray')) {
            return;
        }

        setDevices(devicesResponse.content.sort((a: Device, b: Device) => (a.iddevice??0) < (b.iddevice??0) && 1 || -1));

        let extendedResponse: ExtendedDevice[] = await ExtendedEntitiesFactory.getExtendedDevices(devicesResponse.content)
        setDevices(extendedResponse);

        await updateDevicesStatus(extendedResponse); // I pass extendedResponse because "devices" state sometimes does't update in time
    }

    const getFilteredDevices = () => {
        return devices
            .filter((device: Device) => !props.globalFilters.device || device.iddevice === props.globalFilters.device)
            .filter((device: Device) => !props.globalFilters.room || device.room_idroom === props.globalFilters.room);
    }

    // Merge devices objects with updated status: nmk_lastping, main_status, ...
    const updateDevicesStatus = async (extendedDevices?: ExtendedDevice[]) => {
        let statusResponse = await Api.getDevicesStatusValues();
        if (Api.isError(statusResponse, 'mustBeArray')) {
            return;
        }
        if (!devices || devices.length === 0) {
            return;
        }
        let devicesClone = _.cloneDeep(extendedDevices ?? devices);
        for (let deviceClone of devicesClone) {
            let valuesFound = statusResponse.content.find((s: any) => s.iddevice === deviceClone.iddevice);
            if (!valuesFound) {
                continue;
            }
            if (valuesFound.nmk_lastping) {
                deviceClone.nmk_lastping = valuesFound.nmk_lastping;
            }
            if (!valuesFound.status_vars) {
                continue;
            }
            if (valuesFound.status_vars.main_status) {
                deviceClone.main_status = valuesFound.status_vars.main_status;
            }
            // if (valuesFound.battery_life) {
            //     deviceClone.battery_life = valuesFound.battery_life;
            // }
        }
        setDevices(devicesClone);
    }

    useInterval(() => {
        updateDevicesStatus();
    }, 5000);

    const toggleDelete = (deviceId: number | undefined) => {
        if (!deviceId) {
            return;
        }
        if (selectedToDelete.includes(deviceId)) {
            setSelectedToDelete(selectedToDelete.filter(i => i !== deviceId));
        } else {
            setSelectedToDelete([...selectedToDelete, deviceId]);
        }
    };

    const deleteDevices = () => {
        Api.deleteDevices(selectedToDelete).then((values: any) => {
            for (let value of values) {
                Api.isError(value);
            }
            setSelectedToDelete([]);
            getDevices();
        })
    };

    const goToRooms = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('room');
            props.globalFilters.setRoom(roomId);
            props.globalFilters.setComplex(0);
        }
    }

    const goToKeys = (deviceId: number | undefined) => {
        if (deviceId) {
            props.setPrimaryView('key');
            props.globalFilters.setKey(0);
            props.globalFilters.setDevice(deviceId);
            props.globalFilters.setStay(0);
            props.globalFilters.setRoom(0);
        }
    }

    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>{t('id')}</th>
                        <th>{t('name')}</th>
                        <th>{t('entity.room')}</th>
                        <th>{t('entity.key', {context: 'plural'})}</th>
                        <th>{t('device.customer')}</th>
                        <th>{t('status.activated')}</th>
                        <th>{t('status.status')}</th>
                        <th>{t('device.lastPing')}</th>
                        <NewEntity entity={'device'} onClick={() => props.setSecondaryView({entity: 'device', id: 0})}
                                    display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredDevices()
                        .map(device => <tr
                            className={selectedToDelete.includes(device.iddevice ?? 0) ? 'rowSelectedToDelete' : ''}>
                            <td>{device.iddevice}</td>
                            <td>{device.nmk_description}</td>
                            <td>
                                <ConditionalLink
                                    content={device.ex_room?.name ?? device.room_idroom ?? t('none', {context: 'female'})}
                                    onClick={device.room_idroom ? () => goToRooms(device.room_idroom) : null}
                                    appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink content={device.ex_keys?.length}
                                                    onClick={device.ex_keys?.length ? () => goToKeys(device.iddevice) : null}
                                                    appearance={'badge'}/>
                            </td>
                            <td>{device.nmk_custormerid}</td>
                            <td>{device.nmk_active ? t('actions.yes') : t('actions.no')}</td>
                            <td>{device.main_status ? t('device.mainStatus.' + device.main_status) : ''}</td>
                            <td title={Utils.dateTimeFormatter(device.nmk_lastping)}>
                                {Utils.getRelativeTime(device.nmk_lastping)}
                            </td>
                            <td>
                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                            onClick={() => props.setSecondaryView({entity: 'device', id: device.iddevice})}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    </button>
                                    <button className="btn btn-sm btn-info"
                                            title={t('actions.see') + ' ' + t('entity.log').toLowerCase()}
                                            onClick={() => setLogDeviceSelected(device.iddevice)}>
                                        <FontAwesomeIcon icon={['fas', 'history']}/>
                                    </button>
                                    <button
                                        className={'btn btn-sm btn-danger ' + (selectedToDelete.includes(device.iddevice ?? 0) ? 'active' : '')}
                                        title={selectedToDelete.includes(device.iddevice ?? 0) ? t('actions.unmark') : t('actions.markToDelete')}
                                        onClick={() => toggleDelete(device.iddevice)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'device'} onClick={() => props.setSecondaryView({entity: 'device', id: 0})}
                            display={'card'}/>
                {getFilteredDevices()
                    .map(device => <div
                        className={'card ' + (selectedToDelete.includes(device.iddevice ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={device.iddevice}>
                        <div className="card-header">
                            <EntityImage entityType="device" entity={device} shape="landscape73"
                                        customImage={device.image_filename}/>
                        </div>
                        <div className="card-body">
                            <div className="cardTitle">
                                {device.nmk_description}
                            </div>
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td>{device.iddevice}</td>
                                </tr>
                                <tr>
                                    <td>{t('entity.room')}</td>
                                    <td>
                                        <ConditionalLink
                                            content={device.ex_room?.name ?? device.room_idroom ?? t('none', {context: 'female'})}
                                            onClick={device.room_idroom ? () => goToRooms(device.room_idroom) : null}
                                            appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.key', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={device.ex_keys?.length}
                                                        onClick={device.ex_keys?.length ? () => goToKeys(device.iddevice) : null}
                                                        appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('device.customer')}</td>
                                    <td>{device.nmk_custormerid}</td>
                                </tr>
                                <tr>
                                    <td>{t('status.activated')}</td>
                                    <td>{device.nmk_active ? t('actions.yes') : t('actions.no')}</td>
                                </tr>
                                <tr>
                                    <td>{t('status.status')}</td>
                                    <td>{device.main_status ? t('device.mainStatus.' + device.main_status) : ''}</td>
                                </tr>
                                <tr>
                                    <td>{t('device.lastPing')}</td>
                                    <td title={Utils.dateTimeFormatter(device.nmk_lastping)}>
                                        {Utils.getRelativeTime(device.nmk_lastping)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                        onClick={() => props.setSecondaryView({entity: 'device', id: device.iddevice})}>
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    <span>{t('actions.edit')}</span>
                                </button>
                                <button className="btn btn-sm btn-info"
                                        title={t('actions.see') + ' ' + t('entity.log').toLowerCase()}
                                        onClick={() => setLogDeviceSelected(device.iddevice)}>
                                    <FontAwesomeIcon icon={['fas', 'history']}/>
                                </button>
                                <button
                                    className={'btn btn-sm btn-danger ' + (selectedToDelete.includes(device.iddevice ?? 0) ? 'active' : '')}
                                    title={selectedToDelete.includes(device.iddevice ?? 0) ? t('actions.unmark') : t('actions.markToDelete')}
                                    onClick={() => toggleDelete(device.iddevice)}>
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                </button>
                            </div>
                        </div>
                    </div>)}
            </div>;
        }
    }

    return <div className="primaryViewContainer">
        <ConfirmDeleteBox entityType={'device'} listOfSelectedEntities={selectedToDelete}
                          onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteDevices()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
        <LogModal key={logDeviceSelected} deviceId={logDeviceSelected} keyId={undefined}
                  onClose={() => setLogDeviceSelected(undefined)}
                  title={t('entity.log') + ' ' + t('of').toLowerCase() + ' ' + devices.find(d => d.iddevice === logDeviceSelected)?.nmk_description}/>
    </div>;
}

