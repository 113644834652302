import {Complex, Device, Key, Log, Room, Stay} from "./generated/Entities";
import Api from "./Api";

export interface ExtendedStay extends Stay {
    ex_room?: Room;
    ex_keys?: Key[];
}

export interface ExtendedRoom extends Room {
    ex_complex?: Complex;
    ex_devices?: Device[];
    ex_stays?: Stay[];
    ex_keys?: Key[];
}

export interface ExtendedComplex extends Complex {
    ex_rooms?: Room[];
}

export interface ExtendedDevice extends Device {
    ex_room?: Room;
    ex_keys?: Key[];
}

export interface ExtendedLog extends Log {
    ex_key?: Key;
    ex_device?: Device;
}

export interface ExtendedKey extends Key {
    ex_device?: Device;
    ex_room?: Room;
    /*ex_stay?: Stay;*/
}

/**
 * Get extended entities (entities with more data) from normal entities
 */
export class ExtendedEntitiesFactory {
    static getExtendedStays(stays: Stay[]): Promise<ExtendedStay[]> {
        return new Promise(resolve => {
            Promise.all([Api.searchRoom(), Api.searchKey()]).then(([rooms, keys]) => {
                stays.forEach((stay: ExtendedStay) => {
                    stay.ex_room = rooms.content?.find((room: Room) => room.idroom === stay.room_idroom);
                    stay.ex_keys = keys.content?.filter((key: Key) => key.info_idstay === stay.idstay);
                });
                resolve([...stays]);
            });
        });
    }

    static getExtendedRooms(rooms: Room[]): Promise<ExtendedRoom[]> {
        return new Promise(resolve => {
            Promise.all([Api.searchComplex(), Api.searchDevice(), Api.searchStay(), Api.searchKey()]).then(([complexes, devices, stays, keys]) => {
                rooms.forEach((room: ExtendedRoom) => {
                    room.ex_complex = complexes.content?.find((complex: Complex) => complex.idcomplex === room.complex_idcomplex);
                    room.ex_devices = devices.content?.filter((device: Device) => device.room_idroom === room.idroom);
                    room.ex_stays = stays.content?.filter((stay: Stay) => stay.room_idroom === room.idroom);
                    room.ex_keys = keys.content?.filter((key: Key) => room.ex_devices?.map(d => d.iddevice).includes(key.device_iddevice));
                });
                resolve([...rooms]);
            });
        });
    }

    static getExtendedComplexes(complexes: Complex[]): Promise<ExtendedRoom[]> {
        return new Promise(resolve => {
            Promise.all([Api.searchRoom()]).then(([rooms]) => {
                complexes.forEach(complex => {
                    (complex as ExtendedComplex).ex_rooms = rooms.content?.filter((room: Room) => room.complex_idcomplex === complex.idcomplex);
                });
                resolve([...complexes]);
            });
        });
    }

    static getExtendedDevices(devices: Device[]): Promise<ExtendedDevice[]> {
        return new Promise(resolve => {
            Promise.all([Api.searchRoom(), Api.searchKey()]).then(([rooms, keys]) => {
                devices.forEach(device => {
                    (device as ExtendedDevice).ex_room = rooms.content?.find((room: Room) => room.idroom === device.room_idroom);
                    (device as ExtendedDevice).ex_keys = keys.content?.filter((key: Key) => key.device_iddevice === device.iddevice);
                });
                resolve([...devices]);
            });
        });
    }

    static getExtendedLogs(logs: Log[]): Promise<ExtendedLog[]> {
        return new Promise(resolve => {
            Promise.all([Api.searchKey(), Api.searchDevice()]).then(([keys, devices]) => {
                logs.forEach(log => {
                    (log as ExtendedLog).ex_key = keys.content?.find((key: Key) => key.idkey === log.key_idkey); // TODO log.key_idkey come in string format
                    (log as ExtendedLog).ex_device = devices.content?.find((device: Device) => device.iddevice === log.device_iddevice); // TODO log.key_idkey come in string format
                });
                resolve([...logs]);
            });
        });
    }

    static getExtendedKeys(keys: Key[]): Promise<ExtendedKey[]> {
        return new Promise(resolve => {
            // ATENTION: Don't use getExtendedStays here because it will cause a circular loop
            Promise.all([Api.searchDevice(), Api.searchRoom()/*, Api.searchStay()*/]).then(([devices, rooms/*, stays*/]) => {
                keys.forEach(key => {
                    (key as ExtendedKey).ex_device = devices.content?.find((device: Device) => device.iddevice === key.device_iddevice);
                    (key as ExtendedKey).ex_room = rooms.content?.find((room: Room) => room.idroom === (key as ExtendedKey).ex_device?.room_idroom);
                    /*(key as ExtendedKey).ex_stay = stays.content?.find((stay: Stay) => stay.idstay === key.info_idstay);*/
                });
                resolve([...keys]);
            });
        });
    }

}
